<template>
  <li v-if="bundle">
    <span class="gb-info-tarif">
      {{ bundle.dataAmountGb() }} GB <span>{{ bundle.duration }} days</span>
    </span>
    <router-link class="btn btn-by" :to="bundlePath">
      <span>${{ bundle.priceUsd() }}</span> Buy
    </router-link>
  </li>
</template>

<script setup lang="ts">
import { computed, ComputedRef, defineProps, PropType } from "vue";
import { Bundle } from "@/stores/bundle";
import { Country } from "@/stores/data";
const props = defineProps({
  bundle: Bundle,
  requestedCountries: {
    type: Array as PropType<Array<Country>>,
    default: () => [],
  },
});

const bundlePath: ComputedRef<string> = computed(() => {
  if (props.requestedCountries?.length) {
    return `/bundle/${props.bundle?.id}?requestedCountries=${JSON.stringify(
      props.requestedCountries.map((c) => c.iso)
    )}`;
  } else {
    return `/bundle/${props.bundle?.id}`;
  }
});
</script>

<style scoped></style>
