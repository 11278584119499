import { TOKEN_LOCAL_KEY } from "@/stores/user";
import { types } from "sass";
import { NotificationType, useAppStore } from "@/stores/app";
import Error = types.Error;

export enum QueryMethod {
  GET,
  POST,
  DELETE,
}

export enum ErrorType {
  userNotExist,
  codeInvalid,
  notRegistered,
  tokenInvalid,
  bundleNotFound,
  transactionNotFound,
  transactionAlreadyUse,
  esimCreateError,
  esimNotFound,
}

export class QueryOptions {
  path = "";
  method: QueryMethod = QueryMethod.GET;
  params?: Record<string, any> | null = null;
}

export default async function query(
  options: QueryOptions
): Promise<ErrorType | any> {
  let url = `${process.env.VUE_APP_API_URL}/${options.path}`;

  if (options.params) {
    options.params.platform = 2;
  } else {
    options.params = { platform: 2 };
  }

  let userUtm = {};
  try {
    userUtm = JSON.parse(localStorage.getItem("user_utm") ?? "");
    // eslint-disable-next-line no-empty
  } catch (e) {}
  options.params.userUtm = userUtm;

  console.log(userUtm, localStorage.getItem("user_utm"));

  if (options.method === QueryMethod.GET && !!options.params) {
    for (const [key, value] of Object.entries(options.params)) {
      if (Array.isArray(options.params[key])) {
        options.params[key] = JSON.stringify(value);
      }
    }

    url += `?${new URLSearchParams(options.params)}`;
  }

  const response: Response = await fetch(url, {
    method: QueryMethod[options.method],
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem(TOKEN_LOCAL_KEY)}`,
    },
    credentials: "include",
    body:
      options.method === QueryMethod.GET
        ? null
        : JSON.stringify(options.params),
  });
  const json = await response.json();

  if (json?.error === true && json.id >= 0 && json.id in ErrorType) {
    const error: Error = ErrorType[json.id];

    return error;
  }

  return json;
}
